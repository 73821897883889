.myNavItem
{
      padding: 9px;
      text-decoration: none !important;
}

.navItemTitleBox
{
      color: black;
}

/* if screen is smaller than sm, then this code runs */
@media only screen and (max-width: 575px)
{
      .navItemTitle
      {
            display: none;
      }

      .myNavItem
      {
            padding: 20px;
      }

      .navItemTitleBox
      {
            font-size: 19px;
      }
}

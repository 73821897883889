/* if screen is smaller than sm, then this code runs */
@media only screen and (max-width: 575px)
{
      .card-home-box
      {
            padding: 0 !important;
      }
      
      .cardAndDetailsBox
      {
            width: 100%;
      }

      .cardTop
      {
            max-width: 45%;
            min-width: 45%;
      }

      .offerCardBody
      {
            border-bottom: 2px solid rgb(235, 235, 235);
      }
}


/* if screen is larger than xs */
@media only screen and (min-width: 575px)
{
      .card-home
      {
            min-width: 17rem !important;
            max-width: 17rem !important;
      }
}

.bviolet
{
      border: 2px solid violet !important;
}

.bblue
{
      border: 2px solid blue !important;
}

.bgreen
{
      border: 2px solid green !important;
}

.byellow
{
      border: 2px solid yellow !important;
}

.borange
{
      border: 2px solid orange !important;
}

.bred
{
      border: 2px solid red !important;
}

.c-pointer
{
      cursor: pointer;
}

body
{
      background-color: rgb(250, 250, 250);
}

/* removing bootstrap default focus effects */
select:focus, button:focus, input:focus, textarea:focus
{
      box-shadow: none !important;
      border-color: #CED4DA !important;
}

/* if screen is smaller than xs */
@media only screen and (max-width: 575px)
{
      .addNewOfferBtn
      {
            margin-bottom: 50px;
            margin-left: -14px !important;
      }

      .cardBox
      {
            width: 100% !important;
            margin-bottom: 50px;
      }

      .card
      {
            display: flex;
            flex-direction: row !important;
            width: 100% !important;
      }

      .card-body
      {
            width: 100%;
            padding-left: 10px !important;
            padding-right: 10px !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
      }
      
      .cardBtnBox
      {
            display: flex;
            justify-content: space-around !important;
      }

      .card-img
      {
            max-height: 100%;
            object-fit: contain;
      }

      .cardImg
      {
            border-radius: 4px;
      }

      .cardImgBox
      {
            height: 125px;
      }
}


/* if screen is larger than xs */
@media only screen and (min-width: 575px)
{
      .card
      {
            height: 21rem;
      }

      .addNewOfferBtn
      {
            margin-top: 50px;
            margin-bottom: 60px !important;
      }

      .cardBox
      {
            display: flex;
            margin-bottom: 50px !important;
            margin-left: 3.5% !important;
      }

      .card-text
      {
            margin-left: 10px;
            margin-right: 10px;
      }

      .card-body
      {
            padding-left: 0px !important;
            padding-right: 0px !important;
            padding-top: 15px !important;
            padding-bottom: 10px !important;
      }

      .cardImg
      {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
      }

      .cardImgBox
      {
            height: 145px;
      }
}

.card-img
{
      max-height: 100%;
      object-fit: contain;
}

/* this moveFormBox class is used in logged out pages */
.moveFormBox
{
      position: relative;
      animation: moveFormBox 0.3s ease;
}
@keyframes moveFormBox
{
      from {top: -25px;}
      to {top: 0px;}
}


/* if screen is smaller than sm, then this code runs */
@media only screen and (max-width: 575px)
{
      .outerFormBox
      {
            margin-top: 30% !important;
      }

}


.color-changing-border
{
      animation: color-changing-border 2s linear infinite;
      border-width: 3px !important;
      border-style: solid;
}
@keyframes color-changing-border
{
      0%, 100% {border-color: #ff1493;}
      25% {border-color: #c8661f;}
      50% {border-color: #0091EA;}
      75% {border-color: #9400d3;}
}

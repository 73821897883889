:root
{
      --profilePageWidth: 575px;
}

.userNameImgBox
{
      max-width: var(--profilePageWidth);
      width: var(--profilePageWidth);
}

.optionsBox
{
      max-width: var(--profilePageWidth) !important;
}

.lightAsh
{
      color: rgb(203, 202, 202);
}

.menuItem
{
      color: black;
}

.menuItem:hover
{
      text-decoration: none;
      color: black;
}


/* if screen is larger than xs */
@media only screen and (min-width: 575px)
{
      .userNameImgBox
      {
            margin-top: 10px;
      }
      
}

/* if screen is smaller than sm, then this code runs */
@media only screen and (max-width: 575px)
{
      .offerDetails
      {
            margin-top: 10px;
            margin-left: 5px;
            margin-right: 5px;
      }
}


/* if screen is larger than xs */
@media only screen and (min-width: 575px)
{
      .offerDetails
      {
            margin-top: 10px;
      }

      /* .card-home:hover
      {
            filter: brightness(0.92);
      } */
}


/* scrollbar customization start */
/* width */
::-webkit-scrollbar
{
      width: 10px;
}
    
/* Track */
::-webkit-scrollbar-track 
{
      background: #f1f1f1;
      border-radius: 5px;
}
     
/* Handle */
::-webkit-scrollbar-thumb 
{
      background: #b0b0b0;
      border-radius: 5px;
}
    
/* Handle on hover */
::-webkit-scrollbar-thumb:hover
{
      background: #8b8b8b;
}

/* Handle on click */
::-webkit-scrollbar-thumb:active
{
      background: #646464;
}
/* scrollbar customization end */

/* if screen is smaller than xs */
@media only screen and (max-width: 575px)
{
      .topicCheckbox
      {
            min-width: 35px;
            min-height: 35px;
      }
}


/* if screen is larger than xs */
@media only screen and (min-width: 575px)
{
      .topicCheckbox
      {
            width: 30px;
            height: 30px;
      }
}
